import { CreateControllerFn } from '@wix/yoshi-flow-editor';

const createController: CreateControllerFn = async ({ controllerConfig }) => {
  const { wixCodeApi } = controllerConfig;

  const openCompareLightbox = (productId: string): boolean => {
    wixCodeApi.window.openLightbox('add-to-compare', { productId });
    return true;
  };

  return {
    async pageReady() {
      controllerConfig.setProps({
        onCompareButtonClick: openCompareLightbox,
      });
    },
    exports: () => ({
      set productId(value: any) {
        controllerConfig.setProps({
          productId: value,
        });
      },
    }),
  };
};

export default createController;
